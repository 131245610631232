import React, { useEffect, useState } from 'react';
import Table from 'components/Table/Table.js';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';
const useStyles = makeStyles(styles);

export default function NSArrow(props) {
    const classes = useStyles();

    const [pumpStatus, setPumpStatus] = useState([]);

    useEffect(() => {
        const _pumpStatus = [
            ['Trend', props.entry.trend],
            ['Direction', props.entry.direction]
        ];

        setPumpStatus(_pumpStatus);
    }, [props.entry]);

    // Calculate trend based on the second oldest entry.
    return (
        <Table
            tableHeaderColor="primary"
            tableHead={[]}
            tableData={pumpStatus}
            colorsColls={['primary']}
            customCellClasses={[classes.centerCellText]}
            customClassesForCells={[1]}
        />
    );
}
