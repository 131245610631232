/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import styles from 'assets/jss/material-dashboard-pro-react/components/footerStyle.js';

const useStyles = makeStyles(styles);

export default function Footer(props) {
    const classes = useStyles();
    const { fluid, white, rtlActive } = props;
    var container = cx({
        [classes.container]: !fluid,
        [classes.containerFluid]: fluid,
        [classes.whiteColor]: white
    });
    var anchor =
        classes.a +
        cx({
            [' ' + classes.whiteColor]: white
        });
    var block = cx({
        [classes.block]: true,
        [classes.whiteColor]: white
    });

    const supportUrl =
        process.env.SERENDIPITY_ENV == 'local'
            ? 'https://support.test.serendipitybio.com'
            : 'https://support.serendipitybio.com';

    return (
        <footer className={classes.footer}>
            <div className={container}>
                <div className={classes.left}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <a href="#home" className={block}>
                                {'Serendipity Bio'}
                            </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <a href="/about-us" className={block}>
                                {'About Us'}
                            </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <a href={supportUrl} className={block}>
                                {'Support'}
                            </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <a href="/contact" className={block}>
                                {'Contact'}
                            </a>
                        </ListItem>
                    </List>
                </div>
                <p className={classes.right}>
                    &copy; {1900 + new Date().getYear()} by{' '}
                    <a href="/" target="_self">
                        Serendipity Bio
                    </a>
                    <span> v.{process.env.REACT_APP_SERENDIPITY_VERSION}</span>
                </p>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    fluid: PropTypes.bool,
    white: PropTypes.bool,
    rtlActive: PropTypes.bool
};
