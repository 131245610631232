import React, { useState, useEffect } from 'react';
import { AccessTime } from '@material-ui/icons';
import Icon from '@material-ui/core/Icon';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CardIcon from 'components/Card/CardIcon.js';
import Table from 'components/Table/Table.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

const PUMP_STATUS_FIELDS = ['BATTERY', 'RESERVOIR', 'BOLUSING', 'SUSPENDED'];

export default function NSPumpCard(props) {
    const classes = useStyles();

    const [pumpStatus, setPumpStatus] = useState([]);

    useEffect(() => {
        const PUMP_MAP = {
            BATTERY: (_battery) => {
                return ['Battery', _battery.percent + '%'];
            },
            RESERVOIR: (_resevoir) => {
                return ['Resevoir', _resevoir + ' units'];
            },
            BOLUSING: (_bolus) => {
                return ['Bolus', _bolus ? 'Active' : 'Not Active'];
            },
            SUSPENDED: (_suspend) => {
                return ['Suspend', _suspend ? 'Suspended' : 'Active'];
            }
        };

        const parsedPumpStatus = PUMP_STATUS_FIELDS.reduce(
            (pumpData, field) => {
                if (props.pumpStatus.hasOwnProperty(field.toLowerCase())) {
                    const _status = PUMP_MAP[field](
                        props.pumpStatus[field.toLowerCase()]
                    );
                    pumpData.push(_status);
                }
                return pumpData;
            },
            []
        );
        setPumpStatus(parsedPumpStatus);
    }, [props.pumpStatus]);

    return (
        <Card>
            <CardHeader color="info" stats icon>
                <CardIcon color="info">
                    <Icon>featured_video</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Pump Status</p>
            </CardHeader>
            <CardBody className={classes.cardBodyCenterText}>
                <Table
                    tableHeaderColor="primary"
                    tableHead={[]}
                    tableData={pumpStatus}
                    colorsColls={['primary']}
                    customCellClasses={[classes.centerCellText]}
                    customClassesForCells={[1]}
                />
            </CardBody>
            <CardFooter stats>
                <div className={classes.stats}>
                    <AccessTime /> {props.updateTime}
                </div>
            </CardFooter>
        </Card>
    );
}
