import React, { useState, useEffect, useContext, useCallback } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import AccessTime from '@material-ui/icons/AccessTime';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

// Custom Components
import NSCurrentCard from 'components/Nightscout/CurrentCard';
import NSPumpCard from 'components/Nightscout/PumpCard';
import NSRefreshTimePicker from 'components/Nightscout/RefreshTimePicker';
import NSGlucoseGraph from 'components/Nightscout/GlucoseGraph';
import NSPriorDayGlucoseGraph from 'components/Nightscout/NSPriorDayGlucoseGraph';
import NSLaunchProgress from 'views/SettingsPage/NSLaunchProgress';
import { useInterval } from 'hooks/useIntervalHook';

// Auth Context
import { AuthContext } from 'lib/Auth';
import firebase from '../../services/firebase';

import { sendGtagEvent, GTAG_EVENTS } from 'lib/analytics';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';
import { getEntries, getDeviceStatus } from 'lib/nightscout';

// import deviceStatus from 'device-status';
const useStyles = makeStyles(styles);

let database = firebase.database();

export default function Dashboard(props) {
    const classes = useStyles();

    const { userPreferences, updateUserContext } = useContext(AuthContext);

    const { nightscout, serendipity, uid } = userPreferences;
    const {
        nsRefreshInterval,
        base_url,
        BG_HIGH,
        BG_LOW,
        BG_TARGET_TOP,
        BG_TARGET_BOTTOM
    } = nightscout;

    const { machineHostname } = serendipity;

    const userUrl = machineHostname ? machineHostname : base_url;

    // Set up initial state for the dashboard.
    const [entries, setEntries] = useState([]);

    // Prior day entries.
    const [priorDayEntries, setPriorDayEntries] = useState([]);

    const [fetchingEntries, setFetchingEntries] = useState(true);

    const [fetchingPriorEntries, setFetchingPriorEntries] = useState(true);

    const [chartData, setChartData] = useState({
        data: {
            series: [
                [
                    { x: Date.now() - 3 * 60 * 60 * 1000, y: 0 },
                    { x: Date.now(), y: 0 }
                ],
                [
                    { x: Date.now() - 3 * 60 * 60 * 1000, y: 0 },
                    { x: Date.now(), y: 0 }
                ]
            ],
            labels: [],
            targetLow: BG_TARGET_BOTTOM,
            targetHigh: BG_TARGET_TOP
        }
    });

    const [latestEntry, setLatestEntry] = useState({
        sgv: '',
        trend: '',
        direction: '',
        mills: ''
    });

    const [updateTime, setUpdateTime] = useState('');

    const [deviceStatus, setDeviceStatus] = useState([]);

    const [pumpStatus, setPumpStatus] = useState([]);

    const [refreshInterval, setNSRefreshInterval] = useState(nsRefreshInterval);

    const [bgThresholdColor, setBgThresholdColor] = useState('info');

    const [userPref, setPreferences] = useState({});

    // Reference server/frontend/src/lib/NightscoutApiUtils.js
    // https:bl.ocks.org/bewest/dbe590ab131edfdddd4d988d58c25930
    const fetchEntries = useCallback(async () => {
        setFetchingEntries(true);
        // one reading every 5 minutes * 60 minutes an hour * view duration hours
        // 12 readings an hour  * 3 hours
        const numNsEntries = 12 * 3;

        const dashboardStartTime = Date.now() - 3 * 60 * 60 * 1000;

        const _entries = await getEntries({
            nsApiUrl: userUrl,
            queryStartTime: dashboardStartTime,
            count: numNsEntries
        });

        setEntries(_entries);
        setFetchingEntries(false);
    }, [userUrl]);

    const fetchPriorDayEntries = useCallback(async () => {
        setFetchingPriorEntries(true);
        // one reading every 5 minutes * 60 minutes an hour * view duration hours
        // 12 readings an hour  * 24  hours
        const numNsEntries = 12 * 3;

        // We want to start prior day entries 27 hours ago.
        // Current time - 3 hours and we want to go back to yesterday so subtract 24 more hours.
        const hourShift = 24 + 3;
        const dashboardStartTime = Date.now() - hourShift * 60 * 60 * 1000;
        const dashboardEndTime = Date.now() - 24 * 60 * 60 * 1000;

        const _entries = await getEntries({
            nsApiUrl: userUrl,
            queryStartTime: dashboardStartTime,
            queryEndTime: dashboardEndTime,
            count: numNsEntries
        });

        setPriorDayEntries(_entries);
        setFetchingPriorEntries(false);
    }, [userUrl]);

    const fetchDeviceStatus = useCallback(async () => {
        const deviceStatus = await getDeviceStatus({ nsApiUrl: userUrl });
        setDeviceStatus(deviceStatus);
    }, [userUrl]);

    useInterval(async () => {
        await fetchEntries();
        await fetchDeviceStatus();
        await fetchPriorDayEntries();
    }, nsRefreshInterval);

    // https://www.robinwieruch.de/react-hooks-fetch-data
    useEffect(() => {
        sendGtagEvent(GTAG_EVENTS.VIEW_DASHBOARD, { timeStart: new Date() });
        fetchEntries();
        fetchPriorDayEntries();
    }, [fetchEntries, fetchPriorDayEntries]);

    useEffect(() => {
        fetchDeviceStatus();
    }, [fetchDeviceStatus]);

    useEffect(() => {
        const { formattedEntries } = entries;

        const pFormattedEntries = priorDayEntries.formattedEntries;

        if (formattedEntries && formattedEntries.length > 0) {
            const chartData = {};
            chartData.data = {
                series: [formattedEntries, pFormattedEntries],
                targetHigh: BG_TARGET_TOP,
                targetLow: BG_TARGET_BOTTOM
            };

            const _latestEntry =
                formattedEntries[formattedEntries.length - 1].raw;

            const _priorEntry =
                formattedEntries[formattedEntries.length - 2].raw;

            _latestEntry.trend = _latestEntry.sgv - _priorEntry.sgv;

            setLatestEntry(_latestEntry);
            setChartData(chartData);
        }
    }, [entries, priorDayEntries, BG_TARGET_TOP, BG_TARGET_BOTTOM]);

    useEffect(() => {
        if (latestEntry.date) {
            const start = new Date(latestEntry.date);
            const endTime = Date.now();

            // Millis between readings
            const elapsed = (endTime - start) / 1000;
            const elapsedHours = Math.floor(elapsed / 3600);
            const elapsedMinutes = Math.ceil(elapsed / 60);

            let elapsedTime = '';

            if (elapsedHours > 0 && elapsedMinutes > 0) {
                elapsedTime =
                    'updated ' +
                    elapsedHours +
                    ' hours and ' +
                    elapsedMinutes +
                    ' minutes ago';
            }

            if (elapsedHours === 0 && elapsedMinutes >= 0) {
                elapsedTime = 'updated ' + elapsedMinutes + ' minutes ago';
            }
            setUpdateTime(elapsedTime);
        }
    }, [latestEntry]);

    useEffect(() => {
        if (deviceStatus.length > 0) {
            if (deviceStatus[0].pump) {
                setPumpStatus(deviceStatus[0].pump);
            }
        }
    }, [deviceStatus]);

    useEffect(() => {
        if (latestEntry.sgv) {
            if (latestEntry.sgv >= BG_HIGH || latestEntry.sgv <= BG_LOW) {
                setBgThresholdColor('danger');
            } else {
                setBgThresholdColor('success');
            }
        } else {
            setBgThresholdColor('info');
        }
    }, [latestEntry, BG_HIGH, BG_LOW]);

    useEffect(() => {
        const updatePreferences = async () => {
            let userPrefRef = database.ref('preferences/' + uid);
            userPrefRef.on('value', (snapshot) => {
                const userPreferences = snapshot.val();

                setPreferences(userPreferences);
            });
        };

        updatePreferences();
    }, [uid]);

    const handleNSContextUpdate = (key, val) => {
        const updatedNSContext = nightscout;
        updatedNSContext[key] = val;
        updateUserContext('nightscout', updatedNSContext);
    };

    const handleNSIntervalSelect = (event) => {
        const updatedInterval = event.target.value;
        handleNSContextUpdate('nsRefreshInterval', updatedInterval);
        setNSRefreshInterval(updatedInterval);
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={2}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <NSLaunchProgress userPreferences={userPref} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <NSCurrentCard
                                entry={latestEntry}
                                updateTime={updateTime}
                                bgThresholdColor={bgThresholdColor}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <NSPumpCard
                                updateTime={updateTime}
                                pumpStatus={pumpStatus}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={8}>
                    <GridContainer>
                        <GridItem xs={12} md={12} lg={12}>
                            <Card chart className={classes.cardHover}>
                                <CardHeader color={bgThresholdColor}>
                                    <NSGlucoseGraph
                                        data={chartData.data}
                                        loading={fetchingEntries}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>CGM</h4>
                                </CardBody>
                                <GridContainer className={classes.timePicker}>
                                    <GridItem md={6}>
                                        <div className={classes.stats}>
                                            <div
                                                className={
                                                    classes.cardFooterUpdateTime
                                                }
                                            >
                                                <AccessTime />
                                                {updateTime}
                                            </div>
                                        </div>
                                    </GridItem>
                                    <GridItem md={3}>
                                        <NSRefreshTimePicker
                                            handleSelect={
                                                handleNSIntervalSelect
                                            }
                                            currentInterval={refreshInterval}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} md={12} lg={12}>
                            <Card chart className={classes.cardHover}>
                                <CardHeader color="info">
                                    <NSPriorDayGlucoseGraph
                                        data={chartData.data}
                                        loading={fetchingPriorEntries}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>
                                        CGM - Prior Day
                                    </h4>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
}
