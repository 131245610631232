import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';

import workStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js';
import * as landingCopy from 'assets/copy/LandingPageCopy.js';
import SweetAlert from 'react-bootstrap-sweetalert';

import isEmail from 'validator/lib/isEmail';
import normalizeEmail from 'validator/lib/normalizeEmail';

import jsonp from 'jsonp';

const useStyles = makeStyles(workStyle);

export default function SectionWork({ ...rest }) {
    //https://jfelix.info/blog/kick-start-your-newsletter-mailchimp-custom-form-with-react
    const mcSubscribeUrl =
        'https://serendipitybio.us2.list-manage.com/subscribe/post-json?u=32b96f88286a34cc6ef6f5202&amp;id=9a65c2d469';

    const classes = useStyles();
    const [registerEmail, setregisterEmail] = React.useState('');
    const [registerEmailState, setregisterEmailState] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [alert, setAlert] = React.useState(null);

    const toQueryString = function (params) {
        return Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&');
    };
    // function that returns true if value is email, false otherwise
    const verifyEmail = (value) => {
        if (isEmail(value)) {
            value = normalizeEmail(value);
            return true;
        }
        return false;
    };

    const hideAlert = () => {
        setAlert(null);
    };

    const subscribeError = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: 'block' }}
                title="An error occurred."
                onCancel={() => hideAlert()}
                onConfirm={() => hideAlert()}
                confirmBtnCssClass={classes.button + ' ' + classes.success}
                confirmBtnText="sign up"
            >
                Your email address may already be subscribed.
                <h5>Please check your entry and try again.</h5>
            </SweetAlert>
        );
    };

    //https://humble.dev/creating-a-nice-loading-button-with-react-hooks
    //material-ui.com/components/progress/
    const registerClick = async () => {
        setLoading(true);
        if (registerEmailState === 'error' || registerEmail.length === 0) {
            setLoading(false);
            setregisterEmailState('error');
        } else {
            const params = toQueryString({ EMAIL: registerEmail });
            const subUrl = mcSubscribeUrl + '&' + params;

            jsonp(subUrl, { param: 'c' }, (err, data) => {
                if (err) {
                    setLoading(false);
                    subscribeError();
                } else if (data.result !== 'success') {
                    setLoading(false);
                    subscribeError();
                } else {
                    setLoading(false);
                    setregisterEmail('Thanks for subscribing!');
                }
            });
        }
    };

    return (
        <div className={classes.section} {...rest}>
            <GridContainer justify="center">
                <GridItem cs={12} sm={8} md={10}>
                    {alert}
                </GridItem>
                <GridItem cs={12} sm={8} md={10}>
                    <h2 className={classes.title}>
                        {landingCopy.callToActionButtonText}{' '}
                    </h2>
                    <h4 className={classes.description}>
                        {landingCopy.callToActionHeader}
                    </h4>
                    <form
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        target="_blank"
                        noValidate
                    >
                        <GridContainer justify="center">
                            <GridItem sm={12} md={6}>
                                <CustomInput
                                    error={registerEmailState === 'error'}
                                    labelText="Your Email Address *"
                                    name="EMAIL"
                                    id="mce-EMAIL"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: (event) => {
                                            if (
                                                verifyEmail(event.target.value)
                                            ) {
                                                setregisterEmailState(
                                                    'success'
                                                );
                                            } else {
                                                setregisterEmailState('error');
                                            }
                                            setregisterEmail(
                                                event.target.value
                                            );
                                        },
                                        value: registerEmail,
                                        type: 'email'
                                    }}
                                />
                            </GridItem>
                            <GridItem
                                sm={12}
                                md={8}
                                className={
                                    classes.mrAuto +
                                    ' ' +
                                    classes.mlAuto +
                                    ' ' +
                                    classes.textCenter
                                }
                            >
                                <Button
                                    color="danger"
                                    size="lg"
                                    disabled={loading}
                                    onClick={() => {
                                        registerClick();
                                    }}
                                >
                                    Contact Us
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={35}
                                        thickness={6}
                                        className={classes.submitSpinner}
                                    />
                                )}
                            </GridItem>
                        </GridContainer>

                        <div className={classes.botForm} aria-hidden="true">
                            <input
                                type="text"
                                name="b_32b96f88286a34cc6ef6f5202_9a65c2d469"
                                tabIndex="-1"
                                value=""
                                readOnly
                            />
                        </div>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}
