import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { Storage, Link, Dns, CameraAlt } from '@material-ui/icons';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/NSLaunchStyles.js';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import CardHeader from 'components/Card/CardHeader';
import NSQRCode from './NSQRCode';

const SERVER_STATE = {
    STARTING: { label: 'Starting...', class: 'requested' },
    RUNNING: { label: 'Online', class: 'running' },
    NOT_RUNNING: { label: 'Offline', class: 'stopped' },
    STOPPED: { label: 'Stopped', class: 'stopped' },
    REQUESTED: { label: 'Waiting for resources...', class: 'requested' },
    STOPPING: { label: 'Stopping...', class: 'requested' }
};

const useStyles = makeStyles(styles);

export default function NSLaunchProgress(props) {
    const classes = useStyles();

    const { userPreferences } = props;

    const [serverState, setServerState] = useState('STOPPED');
    const [machineHostname, setMachineHostname] = useState('');

    useEffect(() => {
        const { serendipity } = userPreferences ? userPreferences : {};
        const { machineHostname, serverState } = serendipity ? serendipity : {};

        setServerState(serverState ? serverState : 'STOPPED');
        setMachineHostname(machineHostname ? machineHostname : '');
    }, [props]);

    const getQrDiv = () => {
        if (machineHostname && machineHostname.length > 0) {
            return (
                <div className={classes.serverQrCode}>
                    <NSQRCode machineHostname={machineHostname} />
                </div>
            );
        } else {
            return (
                <div className={classes.serverQrCodeLabel}>
                    <span>Not available</span>
                </div>
            );
        }
    };

    return (
        <Card>
            <CardHeader color="rose" stats icon>
                <CardIcon color="rose">
                    <Dns />
                </CardIcon>
                <p className={classes.cardCategory}>Server Status</p>
            </CardHeader>
            <CardBody>
                <div className={classes.cardContentLeft}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classes.serverStatusIcon}
                                >
                                    <div>
                                        {serverState === 'STARTING' ||
                                        serverState === 'REQUESTED' ||
                                        serverState === 'STOPPING' ? (
                                            <div
                                                className={
                                                    classes.serverStatusProgressLoader
                                                }
                                            >
                                                <CircularProgress
                                                    color={'secondary'}
                                                    size={27}
                                                    thickness={6}
                                                />
                                            </div>
                                        ) : (
                                            <div className={classes.stats}>
                                                <Storage
                                                    className={
                                                        { serverState }
                                                            ? classes[
                                                                  SERVER_STATE[
                                                                      serverState
                                                                  ].class
                                                              ]
                                                            : classes[
                                                                  SERVER_STATE
                                                                      .STARTING
                                                                      .class
                                                              ]
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className={classes.serverStatusLabel}>
                                        {serverState
                                            ? SERVER_STATE[serverState].label
                                            : SERVER_STATE.STARTING.label}
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classes.serverStatusIcon}
                                >
                                    <div className={classes.stats}>
                                        <Link />
                                    </div>
                                    <div className={classes.serverStatusLabel}>
                                        {machineHostname ? (
                                            <div>
                                                <a
                                                    href={machineHostname}
                                                    className={
                                                        classes.hostnameLink
                                                    }
                                                >
                                                    {machineHostname}
                                                </a>
                                            </div>
                                        ) : (
                                            'Not available'
                                        )}
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classes.serverStatusIcon}
                                >
                                    <div className={classes.stats}>
                                        <CameraAlt />
                                    </div>
                                    <div id="qr-code-container">
                                        {getQrDiv()}
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </div>
            </CardBody>
        </Card>
    );
}
