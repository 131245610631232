import { getIdToken } from 'services/auth';
import { sendGtagEvent, GTAG_EVENTS } from './analytics';
export const handlePaymentSubmission = async (subscriptionType) => {
    const idToken = await getIdToken();

    // No user has signed in, redirect the user to create an account.
    if (!idToken) {
        if (subscriptionType.monthly) {
            // Use a query parameter to determine which type of subscription the user has selected.
            sendGtagEvent(GTAG_EVENTS.SIGN_UP);
            window.location.href = '/signup?plan=paid';
        } else {
            window.location.href = '/signup?plan=free';
        }
    } else {
        window.location.href = 'admin';
    }
};
