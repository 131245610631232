const nightscoutStyle = (theme) => ({
    inlineBlock: {
        display: 'inline-block',
        padding: '0px',
        width: 'auto',
        color: 'white'
    },
    circularProgress: {
        textAlign: 'center',
        paddingTop: '150px'
    },
    victoryContainer: {
        height: '350px'
    }
});

export default nightscoutStyle;
