import {
    grayColor,
    successColor,
    dangerColor,
    infoColor
} from 'assets/jss/material-dashboard-pro-react.js';

const NSLaunchStyle = {
    stats: {
        color: grayColor[0],
        fontSize: '19px',
        lineHeight: '33px',
        '& svg': {
            position: 'relative',
            top: '7px',
            width: '25px',
            height: '25px',
            marginRight: '15px'
        },
        '& .fab,& .fas,& .far,& .fal,& .material-icons': {
            position: 'relative',
            top: '4px',
            fontSize: '16px',
            marginRight: '3px'
        }
    },
    running: {
        color: successColor[1]
    },
    requested: {
        color: infoColor[2]
    },
    stopped: {
        color: dangerColor[2]
    },
    cardContentLeft: {
        padding: '15px 20px 15px 10px',
        position: 'relative'
    },
    cardCategory: {
        color: grayColor[0],
        fontSize: '14px',
        paddingTop: '10px',
        marginBottom: '0',
        marginTop: '0',
        margin: '0'
    },
    hostnameLink: {
        color: grayColor[0]
    },
    serverStatusIcon: {
        justifyContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px'
    },
    serverStatusLabel: {
        color: grayColor[0],
        fontSize: '19px',
        lineHeight: '33px',
        paddingLeft: '20px'
    },
    serverStatusProgressLoader: {
        paddingRight: '13px'
    },
    serverQrCodeLabel: {
        paddingLeft: '20px',
        color: grayColor[0],
        fontSize: '19px',
        lineHeight: '33px'
    },
    serverQrCode: {
        paddingLeft: '10px'
    }
};

export default NSLaunchStyle;
