/*eslint-disable*/
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Footer from 'components/Footer/SerendipityFooter.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import loginPageStyle from 'assets/jss/material-kit-pro-react/views/loginPageStyle.js';
import image from 'assets/img/bg7.jpg';
import classNames from 'classnames';
// Firebase auth
import { startFirebaseLogin } from './FirebaseLogin';
import { AuthContext } from '../../lib/Auth';
import 'firebaseui/dist/firebaseui.css';

const useStyles = makeStyles(loginPageStyle);

export default function LoginPage() {
    const classes = useStyles();
    const { isAuthenticated, isLoading } = useContext(AuthContext);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });

    React.useEffect(() => {
        // Start firebase UI.
        if (!isAuthenticated && !isLoading) {
            startFirebaseLogin();
        }
    });

    return (
        <div>
            <Header
                absolute
                color="transparent"
                brand="Serendipity Bio"
                links={<HeaderLinks dropdownHoverColor="info" />}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: 'url(' + image + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center'
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                            <Card>
                                <form className={classes.form}>
                                    <CardHeader
                                        color="danger"
                                        signup
                                        className={classes.cardHeader}
                                    >
                                        <h4 className={classes.cardTitle}>
                                            Log In
                                        </h4>
                                    </CardHeader>
                                    <CardBody signup>
                                        {isLoading ? (
                                            <div
                                                className={
                                                    classes.circularProgress
                                                }
                                            >
                                                <CircularProgress
                                                    className={
                                                        classes.inlineBlock
                                                    }
                                                    size={35}
                                                    thickness={6}
                                                />
                                            </div>
                                        ) : isAuthenticated ? (
                                            <Redirect to="/admin" />
                                        ) : (
                                            <div>
                                                <div id="firebaseui-auth-container"></div>
                                            </div>
                                        )}
                                    </CardBody>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
            <Footer darkFont />
        </div>
    );
}
