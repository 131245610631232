import React from 'react';
import { Redirect } from 'react-router-dom';
import firebase from '../services/firebase';
import 'firebase/firebase-database';
import AdminLayout from 'layouts/Admin';
import Tos from '../components/Terms/Terms';
import { checkIsAuthenticated } from 'services/auth';
let database = firebase.database();

if (process.env.REACT_APP_SERENDIPITY_ENV === 'local') {
    database.useEmulator('localhost', 9000);
    console.log('Using Firebase RTDB emulator for client!');
}

export const getPreferences = function (user) {
    let userPrefRef = database.ref('preferences/' + user.uid);
    return new Promise((resolve, reject) => {
        userPrefRef.on('value', (snapshot) => {
            const userPreferences = snapshot.val();
            resolve(userPreferences);
        });
    });
};

export const setPreferences = async (user, updates) => {
    const { key, val } = updates;

    let userPrefRef = database.ref('preferences/' + user.uid + `/${key}`);

    userPrefRef.update(val, (err) => {
        if (err) {
            console.log('An error occurred saving user settings!', err);
            return false;
        } else {
            return true;
        }
    });
};

export const setTos = async (tosAgree) => {
    const user = await checkIsAuthenticated();

    try {
        let userPrefRef = database.ref(
            'preferences/' + user.uid + '/serendipity/'
        );
        const tosUpdate = { tos: tosAgree, tosAcceptedDate: Date.now() };

        userPrefRef.update(tosUpdate, (err) => {
            if (err) {
                console.log('An error occurred saving user TOS!', err);
                return false;
            }
        });

        return true;
    } catch (e) {
        return false;
    }
};

// 07/06/22 Add logic for the free monthly user here??

export const getSubscriptionStatus = (preferences) => {
    const { serendipity, nightscout } = preferences;
    const { tos } = serendipity;

    if (!tos) {
        return <Tos handleTosSubmit={setTos} />;
    } else {
        if (!serendipity || !nightscout) {
            return <Redirect to="/error" />;
        }
        try {
            const { subscription } = serendipity;

            switch (subscription) {
                case 'active':
                    return <AdminLayout />;
                case 'free-tier':
                    return <AdminLayout />;
                case 'inactive':
                    return <AdminLayout />;
                default:
                    return <Redirect to="/pricing" />;
            }
        } catch (e) {
            return <Redirect to="/error" />;
        }
    }
};

export const getDatabaseRef = () => {
    return database;
};
