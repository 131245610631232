import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import styles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.js';
const useStyles = makeStyles(styles);

export default function NSRefreshTimePicker(props) {
    const classes = useStyles();

    const timeLabels = [
        {
            label: '30 Seconds',
            value: 30 * 1000
        },
        {
            label: '1 Minute',
            value: 60 * 1000
        },
        {
            label: '3 Minutes',
            value: 3 * 60 * 1000
        },
        {
            label: '5 Minutes',
            value: 5 * 60 * 1000
        },
        {
            label: '10 Minutes',
            value: 10 * 60 * 1000
        }
    ];

    return (
        <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
                htmlFor="refresh-interval"
                className={classes.selectLabel}
            >
                Refresh Interval
            </InputLabel>
            <Select
                MenuProps={{
                    className: classes.selectMenu
                }}
                classes={{
                    select: classes.select
                }}
                value={props.currentInterval}
                onChange={props.handleSelect}
                inputProps={{
                    name: 'refreshInterval',
                    id: 'refresh-interval'
                }}
            >
                {timeLabels.map((rangeEntry) => {
                    return (
                        <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                            }}
                            value={rangeEntry.value}
                            key={rangeEntry.label}
                        >
                            {rangeEntry.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
