/*eslint-disable*/
import React from 'react';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline';

// core components
import Heading from 'components/Heading/Heading.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import {
    roundedLineChart,
    straightLinesChart,
    simpleBarChart,
    colouredLineChart,
    multipleBarsChart,
    colouredLinesChart,
    pieChart
} from 'variables/charts.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/chartsStyle.js';

const useStyles = makeStyles(styles);

export default function Charts() {
    const classes = useStyles();
    return (
        <div className={classes.alertsContainer}>
            <div className={classes.comingSoon}>
                <h2>Coming soon...</h2>
            </div>
            <div className={classes.chartsBlur}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card chart>
                            <CardHeader color="rose">
                                <ChartistGraph
                                    className="ct-chart-white-colors"
                                    data={roundedLineChart.data}
                                    type="Line"
                                    options={roundedLineChart.options}
                                    listener={roundedLineChart.animation}
                                />
                            </CardHeader>
                            <CardBody>
                                <h4 className={classes.cardTitle}>
                                    Rounded Line Chart
                                </h4>
                                <p className={classes.cardCategory}>
                                    Line Chart
                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card chart>
                            <CardHeader color="warning">
                                <ChartistGraph
                                    className="ct-chart-white-colors"
                                    data={straightLinesChart.data}
                                    type="Line"
                                    options={straightLinesChart.options}
                                    listener={straightLinesChart.animation}
                                />
                            </CardHeader>
                            <CardBody>
                                <h4 className={classes.cardTitle}>
                                    Straight Lines Chart
                                </h4>
                                <p className={classes.cardCategory}>
                                    Line Chart with Points
                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card chart>
                            <CardHeader color="info">
                                <ChartistGraph
                                    className="ct-chart-white-colors"
                                    data={simpleBarChart.data}
                                    type="Bar"
                                    options={simpleBarChart.options}
                                    responsiveOptions={
                                        simpleBarChart.responsiveOptions
                                    }
                                    listener={simpleBarChart.animation}
                                />
                            </CardHeader>
                            <CardBody>
                                <h4 className={classes.cardTitle}>
                                    Simple Bar Chart
                                </h4>
                                <p className={classes.cardCategory}>
                                    Bar Chart
                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <CardHeader color="info" icon>
                                <CardIcon color="info">
                                    <Timeline />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Coloured Line Chart <small>- Rounded</small>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <ChartistGraph
                                    data={colouredLineChart.data}
                                    type="Line"
                                    options={colouredLineChart.options}
                                    listener={colouredLineChart.animation}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <Timeline />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Multiple Bars Chart{' '}
                                    <small>- Bar Chart</small>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <ChartistGraph
                                    data={multipleBarsChart.data}
                                    type="Bar"
                                    options={multipleBarsChart.options}
                                    listener={multipleBarsChart.animation}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={7}>
                        <Card>
                            <CardHeader color="warning" icon>
                                <CardIcon color="warning">
                                    <Timeline />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Coloured Lines Chart{' '}
                                    <small>- Rounded</small>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <ChartistGraph
                                    data={colouredLinesChart.data}
                                    type="Line"
                                    options={colouredLinesChart.options}
                                    listener={colouredLinesChart.animation}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                        <Card>
                            <CardHeader color="danger" icon>
                                <CardIcon color="danger">
                                    <Timeline />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Pie Chart
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <ChartistGraph
                                    data={pieChart.data}
                                    type="Pie"
                                    options={pieChart.options}
                                />
                            </CardBody>
                            <CardFooter stats className={classes.cardFooter}>
                                <h6 className={classes.legendTitle}>Legend</h6>
                                <i
                                    className={'fas fa-circle ' + classes.info}
                                />{' '}
                                Apple{` `}
                                <i
                                    className={
                                        'fas fa-circle ' + classes.warning
                                    }
                                />{' '}
                                Samsung
                                {` `}
                                <i
                                    className={
                                        'fas fa-circle ' + classes.danger
                                    }
                                />{' '}
                                Windows Phone
                                {` `}
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
