import React from 'react';
import { AccessTime } from '@material-ui/icons';
import Icon from '@material-ui/core/Icon';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CardIcon from 'components/Card/CardIcon.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';
import { makeStyles } from '@material-ui/core/styles';
import NSArrow from './Arrow';
const useStyles = makeStyles(styles);

const TREND = {
    FLAT: 'FLAT',
    UP: 'UP',
    DOWN: 'DOWN',
    FORTYFIVEUP: 'FORTYFIVEUP',
    FORTYFIVEDOWN: 'FORTYFIVEDOWN'
};

export default function NSCurrentCard(props) {
    const classes = useStyles();

    const getIconComponent = () => {
        let arrowComponent;

        switch (props.entry.direction.toUpperCase()) {
            case TREND.FLAT:
                arrowComponent = 'arrow_forward';
                break;
            case TREND.UP:
                arrowComponent = 'arrow_upward';
                break;
            case TREND.DOWN:
                arrowComponent = 'arrow_downward';
                break;
            case TREND.FORTYFIVEUP:
                arrowComponent = 'trending_up';
                break;
            case TREND.FORTYFIVEDOWN:
                arrowComponent = 'trending_down';
                break;
            default:
                arrowComponent = 'help_outline';
                break;
        }

        return <Icon>{arrowComponent}</Icon>;
    };

    return (
        <Card>
            <CardHeader color={props.bgThresholdColor} stats icon>
                <CardIcon color={props.bgThresholdColor}>
                    {getIconComponent()}
                </CardIcon>
                <p className={classes.cardCategory}>Current Reading</p>
                <h3 className={classes.cardTitle}>{props.entry.sgv}</h3>
            </CardHeader>
            <CardBody>
                <NSArrow entry={props.entry} />
            </CardBody>
            <CardFooter chart>
                <div className={classes.stats}>
                    <AccessTime /> {props.updateTime}
                </div>
            </CardFooter>
        </Card>
    );
}
