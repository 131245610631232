import {
    successColor,
    tooltip,
    cardTitle,
    grayColor
} from 'assets/jss/material-dashboard-pro-react.js';

import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.js';

const dashboardStyle = {
    ...hoverCardStyle,
    tooltip,
    centerCellText: {
        textAlign: 'center'
    },
    cardTitle: {
        ...cardTitle,
        marginTop: '0px',
        marginBottom: '3px'
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    },
    cardProductTitle: {
        ...cardTitle,
        marginTop: '0px',
        marginBottom: '3px',
        textAlign: 'center'
    },
    cardCategory: {
        color: grayColor[0],
        fontSize: '14px',
        paddingTop: '10px',
        marginBottom: '0',
        marginTop: '0',
        margin: '0'
    },
    cardProductDesciprion: {
        textAlign: 'center',
        color: grayColor[0]
    },
    cardBodyCenterText: {
        textAlign: 'center'
    },
    stats: {
        color: grayColor[0],
        fontSize: '12px',
        lineHeight: '22px',
        display: 'inline-flex',
        '& svg': {
            position: 'relative',
            top: '4px',
            width: '16px',
            height: '16px',
            marginRight: '3px'
        },
        '& .fab,& .fas,& .far,& .fal,& .material-icons': {
            position: 'relative',
            top: '4px',
            fontSize: '16px',
            marginRight: '3px'
        }
    },
    productStats: {
        paddingTop: '7px',
        paddingBottom: '7px',
        margin: '0'
    },
    successText: {
        color: successColor[0]
    },
    upArrowCardCategory: {
        width: 14,
        height: 14
    },
    underChartIcons: {
        width: '17px',
        height: '17px'
    },
    price: {
        color: 'inherit',
        '& h4': {
            marginBottom: '0px',
            marginTop: '0px'
        }
    },
    cardFooterUpdateTime: {
        display: 'inline-flex'
    },

    timePicker: {
        padding: '0',
        paddingTop: '10px',
        margin: '0 15px 10px',
        borderRadius: '0',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: 'transparent',
        border: '0'
    },
    terms: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '9'
    }
};

export default dashboardStyle;
