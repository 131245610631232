import React from 'react';

// @material-ui/core components
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

// Victory chart components
import { VictoryLine, VictoryScatter, VictoryChart } from 'victory';

// NS Styling
import loginPageStyle from './nightscoutStyle';

import NSVictoryTheme from './NSVictoryTheme';

const useStyles = makeStyles(loginPageStyle);

export default function NSGlucoseGraph(props) {
    const classes = useStyles();

    // Width should be dynamic with the page.
    return props.loading > 0 ? (
        <div
            className={
                classes.circularProgress + ' ' + classes.victoryContainer
            }
        >
            <CircularProgress
                className={classes.inlineBlock}
                size={35}
                thickness={4}
            />
        </div>
    ) : (
        <div>
            <VictoryChart
                scale={{ x: 'time' }}
                height={125}
                width={415}
                theme={NSVictoryTheme}
                domain={{ y: [50, 350] }}
            >
                <VictoryLine
                    data={props.data.series[0]}
                    x={(data) => new Date(data.x)}
                    y="y"
                />
                <VictoryScatter
                    data={props.data.series[0]}
                    x={(data) => new Date(data.x)}
                    y="y"
                    size={2}
                />
                <VictoryLine
                    data={props.data.series[0]}
                    style={{
                        data: { stroke: 'yellow', strokeWidth: 1 }
                    }}
                    y={() => props.data.targetHigh}
                />
                <VictoryLine
                    data={props.data.series[0]}
                    style={{
                        data: { stroke: 'yellow', strokeWidth: 1 }
                    }}
                    y={() => props.data.targetLow}
                />
            </VictoryChart>
        </div>
    );
}
