module.exports = {
    hero: 'A hosted Nightscout solution.',
    heroSub: 'Simple Nightscout setup and maintenance.',
    featureHero: 'We launch and maintain Nightscout for you.',
    primaryFeature: 'We manage Nightscout for you',
    primaryFeatureSub:
        'Launch Nightscout with a push of a button.  No more messing with GitHub, Heroku, or other cloud providers.',
    secondaryFeature: 'Cloud based',
    secondaryFeatureSub:
        'Our service is built on top of the best cloud infrastructure.  Access Nightscout wherever you have internet access.',
    tertiaryFeature: 'MongoDB included',
    tertiaryFeatureSub:
        'Our service includes a preconfigured Mongo database.  The moment you start Nightscout, we automatically deploy your database.  4GB of storage included for free.',
    quadFeature: 'Compatible with an existing Nightscout server.',
    quadFeatureSub:
        'Already have a Nightscout server?  Leverage our service for more reports and alerting, with more features coming!',
    callToActionButtonText: 'Questions?',
    callToActionHeader: "Drop us your email address and we'll get in touch!",
    callToActionSub: 'Contact Us.',
    freeTierPricing: '0.00',
    monthlyTierPricing: '12.99'
};
