import React, { useEffect } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CreditCard from '@material-ui/icons/CreditCard';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody.js';

import { getIdToken } from 'services/auth';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import { getStripeApiPaths } from '../../services/api';
const { createPortalSession } = getStripeApiPaths();

const useStyles = makeStyles(styles);

export default function BillingSettings(props) {
    const classes = useStyles();

    const startPortalSession = async () => {
        const idToken = await getIdToken();
        const portalSession = await fetch(createPortalSession, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken
            }
        });

        if (portalSession.status === 200) {
            const sessionData = await portalSession.json();
            // Redirect to Stripe payment page.
            window.location.href = sessionData.url;
        } else {
            // Go to payment error page.
        }
    };

    useEffect(() => {
        startPortalSession();
    });

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader color="success" text>
                        <CardIcon color="success">
                            <CreditCard />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.cardContentLeft}>
                            <p>Redirecting you to Stripe...</p>
                        </div>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
