import React, { useEffect, useState } from 'react';

// QR Code Library
import QRCode from 'qrcode';

export default function NSQRCode(props) {
    const [qrCodeImage, setCodeImage] = useState(<img alt="Empty QR Code" />);
    // const [machineHostname, setHostname] = useState(props.machineHostname);

    useEffect(() => {
        async function getQrCode() {
            try {
                const apiURL = props.machineHostname;
                const qrCanvas = await QRCode.toString(apiURL, {
                    type: 'img/svg'
                });
                const qrImage = (
                    <img
                        src={`data:image/svg+xml;base64,${window.btoa(
                            qrCanvas
                        )}`}
                        alt={`Your Nightscout URL is: ${apiURL}`}
                        width="100"
                        height="100"
                    />
                );
                setCodeImage(qrImage);
            } catch (err) {
                console.error(err);
            }
        }

        getQrCode();
    }, [props.machineHostname]);

    return <div id="ns-qr-code">{qrCodeImage}</div>;
}
